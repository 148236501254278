$ancho:13.5%;
$ancho-tablet:20%;  

.wrap{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
  
  .wrap-content{
    display:flex;
    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */
    
    .navbar-site{
      position: relative;
      flex-basis: $ancho;
      
      #sidebar-wrapper{
        width: $ancho;
      }
      
      @include breakTablet{
        flex-basis: $ancho-tablet;
        
        #sidebar-wrapper{
          width: $ancho-tablet;
        }
      
      }
    }
  
    .content-site{
      flex-basis: 100%-$ancho;
      
      @include breakTablet{
        flex-basis: 100%-$ancho-tablet;
      }
    }
  
  }
}