@import "variables";

#sidebar-wrapper {
  background: white;
  background: white;
  border-right: 1px solid #dcdcdc;
  z-index: 50;
  position: fixed;
  
  height:100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-right: 1px solid #dcdcdc;
  z-index: 50;

  /* Sidebar Styles */
  ul.sidebar-nav {
    position: relative;
    top: 0;
    width: auto;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      text-indent: 20px;
      line-height: 40px;
      transition: all .3s;
      border: none !important;
      position: relative;

      a {
        &:before {
          transition: all .3s;
          position: absolute;
          content: '';
          height: 1px;
          background: $gris-oscuro;
          width: 100%;
          bottom: 0;
          left: 0;
          transform: scale(0);
          display: block;
        }

        &:hover {
          color: $color_celeste;
          &:before {
            transform: scale(1);
          }
        }
      }

      &.active {
        a.has-arrow {
          background-color: $color_celeste;
          span {
            color: white;
          }
        }
      }

      > a {
        //transition: all .3s;
        display: block;
        text-decoration: none;
        color: $gris-oscuro;
        font-size: 1.3rem;
        padding: .1em .2em !important;
        line-height: 30px;

        &:before {
          transition: all .3s;
          position: absolute;
          content: '';
          height: 2px;
          background: $color_celeste;
          width: 100%;
          bottom: 0;
          left: 0;
          transform: scale(0);
          display: block;
        }

      }

      /*ul.collapse {
        li {
          transition: all .3s;
          position: relative;

          &:before {
            transition: all .3s;
            content: '';
            height: 2px;
            background: $color_celeste;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            transform: scale(0);
          }

          &:hover {
            a {
              color: $color_celeste;
            }
            &:before {
              transform: scale(1);
            }
          }

          a {
            border: none;
          }
        }
      }*/
    }

    > .sidebar-brand {
      height: 65px;
      font-size: 18px;
      line-height: 60px;
      a {
        color: #999999;
        &:hover {
          color: #fff;
          background: none;
        }
      }
    }
  }

  .top {
    //height: 50px;
    padding: 0;
    overflow: hidden;
    margin: 0 auto;

    form {

      margin: 0 auto;
      .form-group {
        margin: 0 auto;
        display: block;
        //border: 1px solid red;

        input {
          width: 90%;
          text-align: center;
          display: block;
          margin: 0 auto;
          color: $gris-oscuro
        }
      }

    }
  }

  .has-arrow {
    cursor: pointer;

    li, a {
      cursor: pointer;
    }
  }

  .titulo-nav {
    color: $gris-oscuro;
    text-transform: uppercase;

    i {
      margin-left: -10px;
    }
  }

  .nombre {
    text-transform: uppercase;
    font-size: 1.4em;
    margin: 6.5rem 0 10px 0;
    color: $gris-oscuro;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    i {
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: center;
      display: block;
    }
  }
}

/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.toggled {
    padding-left: 250px;
  }
}

#wrapper.toggled #sidebar-wrapper {
  //width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 0 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    //width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    //padding: 20px 0px 10px 0;
    position: relative;
    .container-fluid {
      padding: 0;
    }
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}