section[data-section='pagos'] {

  table {
    table-layout: fixed;
    img {
      //max-width: 150px;
      max-height: 100px;
    }
  }

  .loader {
    display: none;
    height: 80vh;
    box-shadow: 0 0 3px black;
    position: fixed;
    left: 50%;
    top: 50%;
    background: white;
    width: 700px;
    z-index: 888;
    transform: translate(-50%, -50%);

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .loader-back {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(black, .5);
    top: 0;
    left: 0;
    z-index: 777;
    display: none;
  }
}