*, *:before, *:after
{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.filtros{
  .bootstrap-select{
    width: 100% !important;
  }
}

html, body
{
    width: 100%;
    height: 100%;
    
    margin: 0;
    padding: 0;
}

body {
  overflow-x: hidden;
  
  a{
    &:hover,&:visited{
      text-decoration: none !important;
    }
  }
}

.anulado{
  background-color: lightgrey;
  text-decoration: line-through;
}

