#navegacion-top{
  border:none;
  margin-bottom: 0;
  border-radius: 0;
  z-index: 90;
  background: $color_celeste;
  box-shadow: 0 0 2px grey;
  a{
    color:white;
    &:hover{

    }
  }

  .dropdown-menu{
    li a{
      color:$gris-oscuro;
    }
  }
}