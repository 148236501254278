
html {
  font-size: 10px;
}


#inicio-crm {
  display: block;
  margin: 0 auto;
  width: 50%;

  @media (max-width: $break-mobile) {
    width: 93%;
  }

  img {
    max-height: 200px;
    margin: 0 auto;
    display: block;
  }
}

.btn {
  //margin-bottom: 5px;
  &:focus {
    outline: none;
  }
}

body {
  margin-top: 2.8em;
  @extend .font-arimo;
  background-color: $back-color-admin;

  ul {
    padding: 0;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  li {
    list-style: none;
  }

  h1 {
    margin-bottom: 5px;
  }
}

.btn-default {
  //background: #f9f9f9;
}

label {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400 !important;
  color: $color_celeste;
  //border-bottom: 1px solid #f2f2f2;
  padding-bottom: 2px;
}

.form-control,
select {
  border-radius: 0 !important;
  box-shadow: none;
  color: #5f5f5f;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  &:focus {
    border-bottom: 1px solid $color_celeste;
    box-shadow: none;
    color: black;
    background: rgba(245, 245, 245, 0.65);
  }
}

.bootstrap-select {
  border-bottom: 0 !important;
}

.btn {
  font-size: 13px;
  //border: none;
  //margin-top: 5px;
  //margin-bottom: 5px;
  //letter-spacing: 1px;
  font-weight: 300 !important;
  @extend .font-raleway;
}

.btn-primary {
  background: $color_celeste;
}

table {
  background: white;
  padding: .4em;
  margin-top: 5px;
  color: black;
  border-collapse: collapse;
  //table-layout: fixed;

  td, th {
    //word-wrap: break-all;
    //white-space:nowrap;
    //max-width: 280px;
    //overflow: auto;
  }

  tr:hover {
    background: lightgrey !important;
    color: #000000;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  @extend .font-raleway;
  font-weight: 200 !important;
}

.border-crm {
  border: 1px solid lightgrey;
  border-top: none;
}

.seccion {
  h1 {
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  //background: white;
  //padding: 1em;
}

.tab-content {
  @extend .border-crm;
  padding: 1em;
  background: white;

  h1,
  h2,
  h3 {
    margin-bottom: 10px;
  }
}

.custom-file-input {
  color: transparent;
  width: 100%;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  width: 100%;
  text-align: center;
  content: 'Seleccionar imagen';
  color: black !important;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #ffffff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.foto-contacto {
  display: none;
  padding: 1em 0 0 0;
  width: 30%;
  margin: 0 auto;
}

.nav-tabs {
  li {
    //border: 1px solid lightgrey;
    //border-bottom: none !important;
    //margin-left: 3px;
  }

  a {
    //border:none !important;
    //background: transparent !important;
  }
}

.card {
  //margin: 7px 0;
  //padding: 1.9em;
  //box-shadow: 0 0 1px #bfbfbf;
  //background: white;
  @extend .card2;

  .titulo {
    padding: .4em .2em;
    border-bottom: 1px solid lightgrey;

    h1,
    h2,
    h3 {
      text-transform: uppercase;
      font-weight: 200;
      letter-spacing: 1px;
    }
  }

  &.card-dash {
    min-height: 450px;
    position: relative;
  }

  h1,
  h2,
  h3 {
    font-weight: 200;
  }

  &.blank {
    box-shadow: none !important;
  }

  select {
    margin: 0 !important;
  }

  fieldset {
    //width: 25%;
    //float: left;
  }

  &.login {
    width: 30%;
    background-color: white;
    margin: 50px auto;
    @media (max-width: 480px) {
      width: 90%;
      text-align: center;
    }

    fieldset {
      width: 100%;
      padding: .4em 0;
    }
  }
}

.card2 {
  position: relative;
  padding: 20px;
  margin: 5px 0 10px 0;
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09), 0 2px 2px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09), 0 2px 2px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09), 0 2px 2px rgba(0, 0, 0, 0.07);
}

fieldset {
  padding: .2em;
}

label {
  //border-top: 1px solid #e5e5e5;
  font-weight: 800;
  font-size: 1em;
  //margin-top: 2px;
  margin: 0;
  text-transform: uppercase;
}

.modal-dialog {
  width: 90% !important;

  select {
    margin: 0 !important;
  }

  fieldset,
  select {
    //width: 100%;
    //float: left;
  }
}

#listarContactos {
  width: 35%;
  float: left;
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;

  #load-more-btn {
    margin-top: 1rem;
    width: 40%;
    text-transform: uppercase;
  }
}

.item-contact {
  display: block;
  font-weight: 200;
  cursor: pointer;
  padding: 1em;
  border: 1px solid lightgrey;
  margin-top: 1px;
  color: black;

  &:hover {
    background: rgb(87, 72, 145);
    color: white;
  }

  &.active {
    background: rgb(87, 72, 145);
    color: white;
    text-decoration: none;
  }

  &:first-child {
    margin-top: 10px;
  }

  i {
    //padding-right: .5em;
  }

  span {
    color: grey;
    margin: 2px 0;
    //width: 100%;
    //float: left;
    display: block;
    //padding: .2em;
    b {
      //display: block;
      //margin-left: 16px;
      font-weight: 400 !important;
      color: black;
    }
  }

  h3,
  h4 {
    margin: 0;
  }
}

.ficha-detalle {
  margin-top: 10px;
  //width: 64%;
  display: block;
  float: right;
  border: 1px solid lightgrey;
  padding: .5em;
  min-height: 300px;
  position: relative;

  ul {
    padding: 1.5em;
  }

  li {
    width: 50%;
    float: left;
    list-style: none;
    //border-bottom: 1px solid lightgrey;
    padding: .6em 0;
  }

  li.comentarios {
    width: 100%;
  }

  h2,
  h3 {
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
  }

  .imagen {
    text-align: center;
    //background: lightgrey;
    margin: 0 auto;
    display: block;
    width: auto;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.item-nota {
  position: relative;
  border: 1px solid lightgrey;
  padding: 1em;
  cursor: pointer;
  //margin-top: 10px;
  .borrar-nota {
    position: absolute;
    right: 2%;
    top: 15%;
    background-color: transparent;
    color: $color_celeste;
    padding: .3em .5em;
    border-color: $color_celeste;

    &:hover {
      background-color: $color_celeste;
      color: white;
    }
  }
}

.form-modal {
  fieldset {
    width: 33.3% !important;
    float: left;
  }
}

#calendario {
  .dia {
    border: 1px solid lightgrey;
    padding: 3em 2em !important;
    position: relative;
    text-align: center;

    &:hover {
      cursor: pointer;
      background: #ededed;
      color: $color_celeste;
    }

    .cantidad-eventos {
      position: absolute;
      left: 0;
      top: 0;
      padding: .2em .6em;
      background: $color_celeste;
      color: white;
      z-index: 777;
    }
  }

  .nuevo-evento {
    position: absolute;
    right: 0;
    top: 0;
    padding: .2em .6em;
    background: green;
    color: white;
    z-index: 777;
  }

  .dias-name {
    text-transform: uppercase;
    font-weight: 500 !important;
    padding: 1em 0;
  }
}

.item-evento {
  border-bottom: 1px solid lightgrey;
  padding: 1em;

  b {
    display: block;
    color: $color_celeste;
    font-weight: 500 !important;
    text-transform: uppercase;
  }

  span {
    width: 25%;
    float: left;
  }
}

.detalle-table {
  width: 35% !important;
}

#busqueda {
  .form-control {
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    outline: none;
  }

  .btn {
    background: transparent;
    color: white;
    border: none
  }
}

#fichas {

  h1 {
    margin-bottom: 10px;
  }

  .nav-tabs {
    li {
      &.active {
        a {
          background: white;
        }
      }

      a {
        position: relative;
        //width: calc(100% / 6);
        //padding: 1.3em;
        text-transform: uppercase;
        background: rgba(white, .6);
        padding: 10px;
        text-align: center;
        margin: 0 3px 0 3px;

        i {
          position: absolute;
          left: 4%;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 1.3em;
        }
      }
    }
  }
}

#permisos-sec {
  .item-permisos {
    text-transform: uppercase;
    padding: .5em;
    border-bottom: 1px solid lightgrey;

    span {
      font-size: 10px;
      color: grey;
    }
  }
}

.ficha-dash {
  h1,
  h2 {
    text-transform: uppercase;
    font-weight: 800 !important;
  }

  .card {
    min-height: 200px;
  }
}

.item-list {
  padding: .4em .6em;
  border-bottom: 1px solid lightgrey;
}

.boton {
  margin: 3px 0;
}

.icon-user {
  overflow: hidden;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
  background-position: center center;
  background-size: cover;
  margin-bottom: 10px;
  border: 3px solid #c0c1c1;
  //box-shadow: 0 0 3px darken($gris-claro,30);
}

.plan-item {
  text-align: center;
  padding: 1.5em 2.8em;
  float: left;
  height: auto;
  border: 1px solid lightgrey;
  width: 100%;

  h1,
  h2,
  h3 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    border-bottom: 1px solid lightgrey;
    padding: .2em 0;
  }

  .btn {
    width: 100%;
  }

  span {
    text-align: center;
    border-bottom: 1px solid lightgrey;
    padding: .5em;
    width: 100%;
    display: block;
  }
}

.informe {
  li {
    padding: .8em;
    width: 19.4%;
    float: left;
    text-align: center !important;
    border: 1px solid lightgrey;
    margin: 3px;

    strong {
      text-align: center !important;
      text-transform: uppercase;
      font-weight: 700 !important;
      display: block;
    }

    span {
      font-size: 2em;
    }
  }
}

.input-group-addon {
  border: none;
  background: transparent;
}

.item-user {
  border: 1px solid lightgrey;
  background: white;
  //margin: 10px 0;
  padding: 1em;
  clear: both;

  h1,
  h2,
  h3 {
    margin: 0;
  }

  button {
    margin-top: 5px;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

.calendar-table {
  text-align: justify !important;

  .listado, .cabecera {
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  .cabecera {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;

  }

  .col {
    flex: 1 1;
    text-align: center;
    padding: 0.4em 0;
  }

  .linea {
    border-bottom: 1px solid lightgrey;
    display: block;
    margin: 0 0 5px 0;
    clear: both;
    height: 1px;
  }

}

.bootstrap-select {
  //border-bottom: 1px solid lightgrey !important;
  border-radius: 0;

  &:hover {
    background: transparent !important;
  }
}

.btn[title]:hover:after {
  content: attr(title);
  padding: 8px;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: -135%;
  text-transform: uppercase;
  z-index: 20;
  text-shadow: none;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 1px;
  border: 1px solid $color_celeste-rgba;
  background: $color_celeste-rgba;
}

.btn {
  position: relative;
}

.titulo-sec {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 10px;
  width: 100%;
  padding: 1em 0 .2em 0;

  h1,
  h2,
  h3 {
    font-weight: 500 !important;
    color: $back-color-top;
    margin: 0;
  }
}

#titulo-empresa {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 51px;
  width: 203px;
  display: flex;
  align-items: center;
  margin-left: 5px;

  img {
    max-height: 38px;
    margin-left: 0.1rem;
  }

  h3,
  h4 {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    color: white;
    margin-left: 6px;
    margin-top: 8px;
  }
}

#user-name {
  padding-bottom: 0px;
  padding-top: 6px;

  small {
    margin-left: 18px;
  }

}

.custom-file-input {
  color: transparent;
  width: 100%;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  width: 100%;
  text-align: center;
  content: 'Seleccionar imagen';
  color: black !important;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999999;
  border-radius: 3px;
  //padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #ffffff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

footer {
  padding: .7em;
  background: #f7f7f7;
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 777 !important;
}

.padding-button {
  padding-left: 2.5em;
  padding-right: 2.5em;

  i {
    margin: 0;
    padding: 0;
  }
}

.no-disp {
  color: lightgrey;
}

.tabla-clientes {
  thead {
    background: #f8f8f8;
  }

  font-size: 12px;
  overflow: visible;
  z-index: 9 !important;

  tr {
    border-bottom: 1px solid lightgrey;
  }

  td {
    white-space: nowrap;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  .acciones {
    overflow: visible;
  }
}

.busqueda-general {
  width: 100%;

  input.form-control {
    color: white !important;
    border: none;
    border-radius: 3rem !important;
    width: 100% !important;
    background: rgba(white, .5) !important;

    &::placeholder {
      text-transform: uppercase;
      letter-spacing: 2px;
      color: rgba(white, .7) !important;
    }
  }


}

.data-evento {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background: white;
  padding: 3rem;
  box-shadow: 0 0 3px grey;
  z-index: 900;
}

#back-previa {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 800;
  display: none;
  left: 0;
  top: 0;
}

#razon-social {
  margin-left: 4.4rem;
  margin-bottom: 1rem;
  display: block;
}

#resul_envio {

  h3, h4, p {
    margin: 3px;
  }

  a {
    color: white;
  }
}

#tabla-clientes {

  .headers {
    display: flex;
    border-bottom: 1px solid grey;
    padding: 0.8rem 0;
    margin-bottom: 2px;

    @include breakMobile {
      flex-direction: column;
    }

    > div {
      -webkit-box-flex: 1;
      flex: 1 1;
      text-align: left;
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  .row-cliente {

    display: flex;
    border-bottom: 1px solid grey;
    padding: 0.8rem 0;
    width: 100%;
    align-items: center;

    &:hover {
      font-weight: 600;
      background: rgba(4, 4, 4, 0.04);
    }

    > div {
      flex: 1 1;
      padding-left: .3rem;
    }

    @include breakMobile {
      flex-direction: column;

      > div {
        //padding: 0.5rem;
        width: 100%;
        text-align: center;
        padding: 0;
      }
    }

  }

  div.acciones {
    flex: 0 0;
    flex-basis: 13%;
  }

}

.inline {
  transform: translateY(22px);
}