$gris: #F5F5F5;
$color_celeste: #177af9;
$color_celeste_ligth: #1d81f9;

$color_celeste-rgba: rgba(25, 129, 194, 0.65);

$back-color-admin: #eef1f2;
$back-color-top: #2F353F;

$gris-oscuro: #1d1e29;
$gris-claro: #edeeee;
$gris-claro-2: #e9ebeb;

$break-mobile:650px;
$break-tablet:1025px;

.font-raleway {
  font-family: 'Raleway', sans-serif !important;
}

.font-arimo {
  //font-family: 'Raleway', sans-serif;
  font-family: 'Arimo', sans-serif !important;
}
