.perfil {
  display: flex;

  .image {
    padding-right:1rem;
    img{
      max-width:300px;
    }
  }

  .data {
    
  }
}
