.section-dash{
		 
		 .list-count {
			 height:auto;
			 display:flex;
			 flex-wrap:wrap;
			 justify-content: space-between;
			 
			 li {
					flex-basis: 49%;
			    text-align: center;
			    text-transform: uppercase;
			    padding: .7em;
			    border: 1px solid #e5e5e5;
			    margin: 5px;
			 }
	 }
}